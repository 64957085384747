import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

import BackgroundImage from "gatsby-background-image"

// Assets
import arrow from "../images/arrow.svg"
import Contact from "../components/contact"

export default function Contatti() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { id: { eq: "cG9zdDoyODYy" } }) {
            nodes {
              id
              title
              contatti {
                immagine {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Contatti" />
          <BackgroundImage
            Tag="section"
            className="breadcrumb-area jarallax"
            fluid={{
              ...data.allWpPage.nodes[0].contatti.immagine.localFile
                .childImageSharp.fluid,
              sizes: "(max-width: 512px) 80vw",
            }}
            backgroundColor={`#C4BAAD`}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-inner">
                    <h1 className="page-title">
                      {data.allWpPage.nodes[0].title}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="contact-area pd-top-100 pd-bottom-100">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-8">
                  <div className="contact-page-map">
                    <iframe
                      title="map"
                      className="w-100"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2869.763758481506!2d8.168351915509227!3d44.005609379110915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d25db10a573737%3A0x33d5335f695bac26!2sCorso%20Dante%20Alighieri%2C%20193%2C%2017021%20Alassio%20SV!5e0!3m2!1sit!2sit!4v1598622208327!5m2!1sit!2sit"
                      style={{ border: 0 }}
                      allowFullScreen
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4">
                  <div className="widget widget-owner-info mt-lg-0 mt-5 contatti-info">
                    <div className="contact-info">
                      <h6 className="mb-5">
                        Passa a trovarci e fissa un appuntamento coi nostri
                        consulenti
                      </h6>
                      <div className="contatti-grid">
                        <div className="col-12 d-flex justify-content-left">
                          <div className="single-contact-info">
                            <p>Orari di apertura:</p>
                            <div className="d-flex">
                              <h5>MARTEDÌ</h5>
                              <img
                                src={arrow}
                                className="arrow mx-2"
                                alt="arrow"
                              />
                              <h5>SABATO</h5>
                            </div>
                            <div className="orario-section">
                              <h5 className="orario">
                                09.30
                                <img
                                  src={arrow}
                                  className="mx-2 arrow"
                                  alt="arrow"
                                />
                                12.30
                              </h5>{" "}
                              <span className="span mx-2">/</span>{" "}
                              <h5 className="orario">
                                15.30
                                <img
                                  src={arrow}
                                  className="mx-2 arrow"
                                  alt="arrow"
                                />
                                19.00
                              </h5>
                            </div>
                          </div>
                        </div>
                        <p className="appuntamento">
                          SI RICEVE SU APPUNTAMENTO <br />
                          (anche la domenica mattina)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Contact bgGrayOpacity="bg-gray-opacity" />
        </Layout>
      )}
    />
  )
}
